import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import {
  OsnTextarea,
  Button,
  OsnInputText,
} from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";
import Reviews from "@identitybuilding/idb-react-reviews/dist/Reviews";

const ReviewEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);

  let [reviewTitle, setReviewTitle] = useState(data.options.review.title);
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "template") {
      entityCopy.options.review.template = keyword;
      adminChange(keyword, action);
    }
    if (action === "title") {
      entityCopy.options.review.title = keyword;
      adminChange(keyword, action);
    }
    if (action === "theme") {
      entityCopy.options.review.theme = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <Reviews
        template={entityCopy.options.review.template}
        theme={entityCopy.options.review.theme}
        reviews={entityCopy.options.review.review}
        title={entityCopy.options.review.title}
      />
    );
    dispatch(updateModules(modulcesCopy));
    // setModules(modulcesCopy);
  };
  const adminChange = (props, action) => {
    axios
      .put(
        `https://onepage-78a00-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/review.json`,
        {
          [action]: props,
          ...entityCopy.options.review,
        }
      )
      .then(async (resp) => {
      });
  };
  return (
    <div className="testBlock">
      <div className="testTitle">Reviews</div>
      <div>
        <span>Title:</span>
        <OsnInputText
          onChange={(e) => setReviewTitle(e.target.value)}
          value={reviewTitle}
          placeholder={reviewTitle}
        />
        <Button
          bgColor={["cn"]}
          size="S"
          text="Change title"
          txtColor="white"
          type="main"
          onClick={() => handler("title", reviewTitle)}
        />
      </div>
      <div>
        <span>Template:</span>
        <ul>
          <li onClick={() => handler("template", 1)}>1</li>
          <li onClick={() => handler("template", 2)}>2</li>
        </ul>
      </div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
    </div>
  );
};

export default ReviewEdit;
