import React, { Fragment, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import auth from "../store/auth";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const history = useHistory();

  const login = (e) => {
    e.preventDefault();
    auth.actions.login(
      usernameInputRef.current.value,
      passwordInputRef.current.value
    );
    setTimeout(() => {
      history.push("/admin");
    }, 500);
  };

  return (
    <section id="login">
      <form className="login-form" onSubmit={login}>
        <div>
          <label>Username</label>
          <input type="text" ref={usernameInputRef} name="username" required />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            ref={passwordInputRef}
            name="password"
            required
          />
        </div>
        <input id="submit-login" name="submit" type="submit" value="Login" />
      </form>
    </section>
  );
};

export default LoginPage;
