import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const About = (props) => {
    const {translate} = useSelector((state) => state.AdminReducer);


    return (
        <section id="about">
                <h2>{props.title}</h2>
                <div className='content'>
                    <figure>
                        <img src={require('../library/images/about.png').default}/>
                    </figure>
                    <div className='text'>
                        <div dangerouslySetInnerHTML={{__html: props.text}} />
                        <div dangerouslySetInnerHTML={{__html: props.text_2}} />
                    </div>
                </div>
        </section>
    )
}

export default About