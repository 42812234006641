const LogoModel = (json, lang) => {
  let data = [];
  if (json && json.establishment_logos && json.establishment_logos.length > 0) {
    for (let i = 0; i < json.establishment_logos.length; i++) {
      let logo = json.establishment_logos[i];
      if (logo) {
        data.push({
          logo: logo.logo,
          default: true,
          color: "",
          order_weight: logo.score,
        });
      }
    }
  }
  if (json && json.enterprise_logos && json.enterprise_logos.length > 0) {
    for (let i = 0; i < json.enterprise_logos.length; i++) {
      let logo = json.enterprise_logos[i];
      if (logo) {
        data.push({
          logo: logo.logo,
          default: true,
          color: "",
          order_weight: logo.score,
        });
      }
    }
  }
  if (json && json.group_logos && json.group_logos.length > 0) {
    for (let i = 0; i < json.group_logos.length; i++) {
      let logo = json.group_logos[i];
      if (logo) {
        data.push({
          logo: logo.logo,
          default: true,
          color: "",
          order_weight: logo.score,
        });
      }
    }
  }

  const arrayFiltered = data.sort((a, b) => {
    if (a.order_weight < b.order_weight) return 1;
    if (a.order_weight > b.order_weight) return -1;
    else {
      return 0;
    }
  });
  
  return arrayFiltered;
};

export default LogoModel;
