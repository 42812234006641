import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const Banner = () => {


    return (
        <section id="banner">
            <figure><img src={require('../library/images/banner/banner-logo.png').default} /></figure>
            <h3>Ontdek het assortiment in onze winkel!</h3>
        </section>
    )
}

export default Banner