import React from 'react'
import { useSelector } from "react-redux";

const TopAanbiedingen = () => {

    const { folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="topaanbiedingen">
                <div className='container'>
                    <figure><a href='https://catalogusnetwerk.be/books/128/index-h5.html#page=1' target="_blank"><img src="https://catalogusnetwerk.be/books/128/content/pages/page1.jpg"/></a></figure>
                    <figure><img src={`https://o-sn.be/kml/test-onepages/${folder_name}/topaanbiedingen.jpg`}/></figure>
                </div>
        </section>
    )
}

export default TopAanbiedingen