import { Fragment } from "react";
import { useSelector } from "react-redux";

const VacatureSuperplanner = (props) => {
  const { modules, translate, folder_name } = useSelector((state) => state.AdminReducer);
  return (
    <section id="vacatureSuperplanner">
        <div className="content">
            <h2>Vacatures</h2>
            <p>Al onze openstaande vacatures kan je hieronder terugvinden. Is dit wat je zoekt? mail dan snel naar <a href="mailto:kimberly.vcz@ad-nederename.be">kimberly.vcz@ad-nederename.be</a></p>
            <a className="figure" href="./superplanner?lang=nl">
                <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature.png`} alt="vacature image"/>
            </a>
        </div>
    </section>
  );
};

export default VacatureSuperplanner;
