import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const Policy = () => {
    const data = useSelector(state => state.AdminReducer)
    let name = data.data.establishment_name ? data.data.establishment_name : data.data.enterprise_name

    useEffect(() => {
        window.scrollTo({ top: '0' })
    }, [])

    return (
        <section id="privacypolicy">

            <div id="pp">
                <div className="pp-top-info">
                    <h1>Privacybeleid van <a href={`https://www.ondernemersnetwerk.be/business/${data.vat && data.vat.replace(/\./g, '')}/${name}/contact`}>
                        {name}</a></h1>Deze Applicatie host deze pagina en verzamelt bepaalde Persoonsgegevens van de Gebruikers.
                </div>

                <h2>Verantwoordelijke voor de verwerking van de Gegevens en Eigenaar</h2>
                <h3>Soorten verzamelde Gegevens</h3>
                Onder het soort gegevens dat deze Applicatie zelf of door middel van
                derden verzamelt, vallen: Cookie, Gebruiksgegevens, voornaam, achternaam, telefoonnummer, adres, e-mailadres
                en stad.De volledige informatie over elk type verzamelde Persoonsgegevens staat in de daaraan gewijde
                artikelen van dit privacybeleid of in specifieke tekst en uitleg voorafgaand aan de Gegevensverzameling.De
                Persoonsgegevens kunnen door de Gebruiker zelf worden verstrekt of automatisch worden verzameld door het
                gebruik van deze Applicatie in het geval van Gebruiksgegevens.Alle door deze Applicatie gevraagde Gegevens
                zijn verplicht, en het niet verstrekken van deze Gegevens maakt dat deze Applicatie zijn diensten niet kan
                verlenen. In de gevallen dat deze Applicatie specifiek aangeeft dat bepaalde Gegevens niet verplicht zijn,
                kunnen Gebruikers kiezen of ze deze Gegevens al dan niet verstrekken, zonder gevolgen voor de
                beschikbaarheid of de werking van de dienst.Als u als Gebruiker niet zeker weet welke Persoonsgegevens
                verplicht zijn, kunt u contact opnemen met de Eigenaar.Het gebruik van Cookies – of andere tracking tools –
                door deze Applicatie of door de eigenaren van de diensten van derden die worden gebruikt door deze
                Applicatie, dient om de door de Gebruiker verzochte dienst te verlenen, naast de andere doelen die in dit
                document en in het Cookiebeleid worden aangegeven, indien daarvan sprake is.De Gebruikers zijn
                verantwoordelijk voor Persoonsgegevens van derden die worden verkregen, gepubliceerd of gedeeld door deze
                Applicatie en zij bevestigen dat zij toestemming van de derde partij hebben om de Gegevens aan de Eigenaar
                te verstrekken.



                <h2>Wijze en plaats van de Gegevensverwerking</h2>
                <h3>Verwerkingsmethode</h3>
                De Verantwoordelijke voor de verwerking van de Gegevens verwerkt de Gegevens van
                de Gebruikers op een correcte wijze en neemt passende beveiligingsmaatregelen om onbevoegde toegang tot,
                bekendmaking, wijziging of vernietiging van de Gegevens zonder toestemming te voorkomen.De verwerking van de
                Gegevens vindt plaats met gebruik van computers en/of IT-tools, waarbij organisatieprocedures en werkwijzen
                worden gevolgd die nauw aansluiten op de aangegeven doelen. Naast de Verantwoordelijke voor de verwerking
                van de Gegevens kunnen de gegevens in bepaalde gevallen ook toegankelijk zijn voor bepaalde met de
                verwerking belaste personen die zijn betrokken bij de exploitatie van de site (beheer, verkoop, marketing,
                juridisch, systeembeheer) of door de Eigenaar als Gegevensverwerkers aangestelde externe partijen (zoals
                externe technische serviceproviders, mailprogramma's, hostingproviders, IT-bedrijven, communicatiebureaus).
                De bijgewerkte lijst van deze partijen kan te allen tijde bij de Verantwoordelijke voor de verwerking van de
                Gegevens worden aangevraagd.

                <h3>Locatie</h3>De Gegevens worden verwerkt in de vestigingskantoren van de
                Verantwoordelijke voor de verwerking van de Gegevens en andere plaatsen waar de partijen zich bevinden, die
                bij de verwerking zijn betrokken. Neem voor meer informatie contact op met de Verantwoordelijke voor de
                verwerking van de Gegevens.

                <h3>Bewaartijd</h3>
                De Gegevens worden bewaard voor de benodigde periode om de
                door de Gebruiker verzochte dienst te verlenen of te voldoen aan de doelen die in dit document zijn
                aangegeven. De Gebruiker kan te allen tijde de Verantwoordelijke voor de verwerking van de Gegevens
                verzoeken om de Gegevensverwerking op te schorten of de Gegevens te verwijderen.



                <h2>Gebruik van de verzamelde Gegevens</h2>
                De Gegevens over de Gebruiker worden verzameld zodat de applicatie de dienst kan
                verstrekken, en voor de volgende doelen: Analyses, Contact met de Gebruiker en Interactie met externe
                sociale netwerken en platforms.De Persoonsgegevens die voor elk doel worden gebruikt, worden in de betrokken
                gedeeltes van dit document aangeduid.



                <h2>Gedetailleerde informatie over de verwerking van Persoonsgegevens</h2>
                Persoonsgegevens worden voor de volgende doelen verzameld met gebruik van de volgende diensten:

                <h3>Analyses</h3>
                De diensten die staan beschreven in dit onderdeel stellen de Eigenaar in staat het
                internetverkeer te volgen en te analyseren en dit kan worden gebruikt om het gebruiksgedrag in kaart te
                brengen.
                <h4>Google Analytics (Google Inc.)</h4>
                Google Analytics is een webanalysedienst die wordt aangeboden
                door Google Inc. (“Google”). Google gebruikt de verzamelde Gegevens om het gebruik van deze Applicatie, te
                volgen en te onderzoeken, om rapporten over de activiteiten ervan op te stellen en deze te delen met andere
                diensten van Google.Google kan de verzamelde Gegevens gebruiken om de advertenties van zijn eigen
                advertentienetwerk in te kaderen en te personaliseren.Verzamelde Persoonsgegevens: Cookie en
                Gebruiksgegevens.Verwerkingslocatie: VS – Privacybeleid – Opt Out
                <h4>Facebook Analytics for Apps (Facebook, Inc.)</h4>
                Facebook Analytics for Apps is een analyseservice die wordt aangeboden door Facebook,
                Inc.Verzamelde Persoonsgegevens: Gebruiksgegevens en verschillende Gegevenstypes die in het privacybeleid
                van de service zijn omschreven.Verwerkingslocatie: VS – Privacybeleid
                <h3>Contact met de gebruiker</h3>
                <h4>Contactformulier (deze Applicatie)</h4>
                Door het contactformulier in te vullen met zijn/haar Gegevens,
                geeft de Gebruiker deze Applicatie toestemming deze gegevens te gebruiken om te antwoorden op
                informatieverzoeken, aanvragen voor prijsopgaven en andere soorten verzoeken die in de kop van het formulier
                worden aangegeven.Verzamelde Persoonsgegevens: achternaam, adres, e-mailadres, stad, telefoonnummer en
                voornaam.
                <h4>Sollicitatieformulier </h4>
                Door het sollicitatieformulier in te vullen met zijn/haar gegevens, geeft de gebruiker toestemming deze
                gegevens te gebruiken om te antwoorden op de ingestuurde sollicitatie. Verzamelde persoonsgegevens:
                voornaam, achternaam, e-mailadres, soort job, beroepservaring, afdeling, motivatie & CV.
                <h3>Interactie met externe sociale netwerken en platforms</h3>
                Met dit soort diensten is rechtstreeks interactie mogelijk
                met sociale netwerken of andere externe platforms vanaf de pagina's van deze Applicatie.De interactie en
                informatie die worden verkregen door deze Applicatie worden altijd beheerst door de privacy-instellingen van
                de Gebruiker voor elk sociale netwerk.Als een dienst is geïnstalleerd waarmee interactie met sociale
                netwerken mogelijk is, kan deze nog steeds verkeersgegevens verzamelen voor de pagina's waarop de dienst is
                geïnstalleerd, zelfs als de Gebruikers deze niet gebruiken.
                <h4>Vind ik leuk-knop van Facebook en sociale widgets (Facebook, Inc.)</h4>
                De vind ik leuk-knop van Facebook en sociale widgets zijn diensten die
                worden aangeboden door Facebook, Inc. waarmee interactie met het sociale netwerk van Facebook mogelijk
                is.Verzamelde Persoonsgegevens: Cookie en Gebruiksgegevens.Verwerkingslocatie: VS – Privacybeleid



                <h2> Bijkomende informatie over Gegevensverzameling en verwerking</h2>
                <h3>Juridische procedures</h3>
                De Verantwoordelijke voor de verwerking van de Gegevens kan de
                Persoonsgegevens van de Gebruiker gebruiken voor juridische doeleinden, voor de rechter of aan gerechtelijke
                procedures voorafgaande fasen in het geval van onrechtmatig gebruik van deze Applicatie of de hieraan
                gerelateerde diensten.De Gebruiker is ervan op de hoogte dat de Verantwoordelijke voor de verwerking van de
                Gegevens genoodzaakt kan zijn de persoonsgegevens bekend te maken op verzoek van bevoegde
                overheidsinstellingen.
                <h3>Bijkomende informatie over de Persoonsgegevens van de Gebruiker</h3>
                Op verzoek van de Gebruiker en naast de informatie die in dit privacybeleid is opgenomen, kan deze
                Applicatie de Gebruiker
                bijkomende en contextuele informatie verstrekken over bepaalde diensten of het verzamelen en verwerken van
                Persoonsgegevens.
                <h3>Systeemlogboeken en onderhoud</h3>
                Met het oog op de werking en het onderhoud, kunnen
                deze Applicatie en externe diensten bestanden verzamelen die de interactie met deze Applicatie registreren
                (systeemlogboeken) of hiervoor andere persoonsgegevens gebruiken (zoals het IP-adres).
                <h3>Niet in dit beleid opgenomen informatie</h3>
                U kunt ten allen tijde meer informatie over het verzamelen of verwerken van
                Persoonsgegevens aanvragen bij de Verantwoordelijke voor de verwerking van de Gegevens. Zie hiervoor de
                contactinformatie aan het begin van dit document.
                <h3>Rechten van Gebruikers</h3>
                Gebruikers hebben te allen
                tijde het recht te weten of hun Persoonsgegevens zijn opgeslagen. Zij kunnen de Verantwoordelijke voor de
                verwerking van de Gegevens verzoeken om bekendmaking van de inhoud en oorsprong hiervan, de juistheid
                hiervan nagaan of verzoeken dat deze worden aangevuld, geannuleerd, bijgewerkt of gecorrigeerd of om
                anonimisering hiervan of om blokkering van gegevens die onrechtmatig worden bewaard. Ook kan bezwaar worden
                gemaakt tegen de verwerking van de gegevens om gegronde redenen. U dient uw verzoeken hiertoe te richten aan
                de Verantwoordelijke voor de verwerking van de Gegevens via de hierboven aangegeven contactinformatie.
                <h3> Deze Applicatie ondersteunt geen verzoeken om uw browseverkeer niet bij te houden.</h3>
                Lees het privacybeleid van de externe diensten die worden gebruikt om te bepalen of deze voldoen aan
                verzoeken om uw
                browseverkeer niet bij te houden (Do Not Track).
                <h3>Wijzigingen van dit privacybeleid</h3>
                De Verantwoordelijke voor de verwerking van de Gegevens behoudt zich het recht voor om dit privacybeleid op
                enig moment te wijzigen door kennisgeving hiervan aan Gebruikers op deze pagina. We raden u aan om deze
                pagina vaak te controleren op eventuele wijzigingen. De datum van de laatste wijziging wordt onderaan de
                pagina aangegeven. Als een Gebruiker bezwaar heeft tegen enige wijziging in het beleid, dient de Gebruiker
                deze Applicatie niet verder te gebruiken. Hij kan de Verantwoordelijke voor de verwerking van de Gegevens
                verzoeken om de Persoonsgegevens te verwijderen. Tenzij anderszins wordt aangegeven, is het op dat moment
                geldige privacybeleid van toepassing op alle Persoonsgegevens die de Verantwoordelijke voor de verwerking
                van de Gegevens over Gebruikers heeft opgeslagen.
                <h3>Informatie over dit privacybeleid</h3>
                De Verantwoordelijke voor de verwerking van de Gegevens is verantwoordelijk voor dit privacybeleid, dat is
                opgesteld met als uitgangspunt de modules die door Iubenda worden verstrekt en gehost op de servers van
                Iubenda.


                <h2>Definities en juridisch kader</h2>
                <h3>Persoonsgegevens (of Gegevens)</h3>
                Alle informatie ten aanzien van een natuurlijk persoon, een
                rechtspersoon, een instelling of een vereniging die rechtstreeks of zelfs onrechtstreeks geïdentificeerd is
                of kan worden door verwijzing naar andere informatie, waaronder een persoonlijk ID-nummer.
                <h3>Gebruiksgegevens</h3>
                Informatie die automatisch wordt verzameld vanuit deze Applicatie (of externe
                diensten die worden ingezet in deze Applicatie ), waaronder: de IP-adressen of domeinnamen van de computers
                die door de Gebruikers van deze Applicatie worden gebruikt, de URI-adressen (Uniform Resource Identifier),
                het tijdstip van het verzoek, de gebruikte methode om het verzoek in te dienen bij de server, de grootte van
                het als reactie hierop ontvangen bestand, de numerieke code die de status van het antwoord van de server
                aangeeft (geslaagd, fout, enz.), het land van oorsprong, de functies van de browser en het besturingssysteem
                van de Gebruiker, de verschillende tijdsgegevens per bezoek (zoals de tijd die op elke pagina van de
                applicatie wordt doorgebracht) en de gegevens over het gevolgde pas binnen de applicatie, in het bijzonder
                gericht op de volgorde van de bezochte pagina's en andere parameters over het besturingssysteem van het
                apparaat en/of de IT-omgeving van de Gebruiker.
                <h3>Gebruiker</h3>
                De persoon die deze Applicatie gebruikt en die moet overeenkomen met of gemachtigd zijn door de Betrokkene
                naar wie de Persoonsgegevens verwijzen.
                <h3> Betrokkene</h3>
                De rechtspersoon of natuurlijke persoon waarop/op wie de Persoonsgegevens betrekking
                hebben.
                <h3>Gegevensverwerker (of Gegevenssupervisor)</h3>
                De natuurlijke persoon, rechtspersoon,
                overheidsadministratie of enige andere instelling, vereniging of organisatie die door de Verantwoordelijke
                voor de verwerking van de Gegevens is gemachtigd om de Persoonsgegevens te verwerken in overeenstemming met
                dit privacybeleid.
                <h3>Verantwoordelijke voor de verwerking van de Gegevens (of Eigenaar)</h3>
                De natuurlijke persoon, rechtspersoon, overheidsadministratie of enige andere instelling, vereniging of
                organisatie met het
                recht, ook gezamenlijk met de Verantwoordelijke voor de verwerking van de Gegevens om besluiten te nemen ten
                aanzien van de doeleinden en de methodes van de verwerking van Persoonsgegevens en de hiervoor gebruikte
                middelen, waaronder beveiligingsmaatregelen ten aanzien van de exploitatie en het gebruik van deze
                Applicatie. De Verantwoordelijke voor de verwerking van de Gegevens is de Eigenaar van deze Applicatie,
                tenzij anderszins wordt aangegeven.

                <h3>Deze Applicatie</h3>
                Het hardware- of softwaregereedschap waarmee de Persoonsgegevens van de Gebruiker worden verzameld.
                <h3>Cookie</h3>
                Klein onderdeel met gegevens dat op het
                apparaat van de Gebruiker wordt opgeslagen.
                <h3>Juridische informatie</h3>
                Kennisgeving aan Europese
                Gebruikers: deze privacyverklaring is opgesteld met inachtneming van de verplichtingen in art. 10 van de
                Europese richtlijn 95/46/EG en de bepalingen van Europese richtlijn 2002/58/EG, evenals de herziening in
                richtlijn 2009/136/EG ten aanzien van cookies.Dit privacybeleid heeft uitsluitend betrekking op deze
                Applicatie.
            </div>
        </section>
    )
}

export default Policy