import React, { useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Icon from "@identitybuilding/idb-react-iconlib";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../actions";
import { OsnCheckbox, Button } from "@identitybuilding/idb-react-ui-elements";
import "./AddModule.css";
import arrayMove from "array-move";

const DragHandle = SortableHandle(() => <Icon name="Dnd" />);

const SortableItem = SortableElement(({ value, moduleHandler, sortIndex }) => (
  <div className="item">
    <DragHandle />
    <OsnCheckbox
      checked={value.active}
      name={value.slug}
      onChange={(e) => {
        moduleHandler(sortIndex);
      }}
      value={value.name}
    />
    {value.preview && (
      <figure>
        <img src={value.preview} />
      </figure>
    )}
  </div>
));

const SortableList = SortableContainer(({ items, moduleHandler }) => {
  return (
    <div className="items">
      {items.map((value, index) => (
        <SortableItem
          key={index}
          index={index}
          sortIndex={index}
          value={value}
          moduleHandler={(i) => moduleHandler(i)}
        />
      ))}
    </div>
  );
});

const AddModule = (props) => {
  const [modules, setModules] = useState(
    useSelector((state) => state.AdminReducer.modules)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Make sure the modules are always sorted based on their orderlist everytime on render
    modules.sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return 0;
    });
  }, []);

  const moduleHandler = (index) => {
    let copy = [...modules];
    copy[index].active = !copy[index].active;
    dispatch(updateModules(copy));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let copy = [...modules];

    copy = arrayMove(copy, oldIndex, newIndex);
    // setTimeout(() => {
    dispatch(updateModules(copy));
    // }, 500)
    setModules(copy);
  };

  return (
    <div className="AddModule_wrapper">
      <div className="addModule">
        <div id="title">
          <h3>
            Which module should be active?
            <p>And in which order?</p>
          </h3>
          <Icon name="Close" onClick={() => props.close()} />
        </div>
        <SortableList
          axis="xy"
          onSortEnd={onSortEnd}
          items={modules}
          useDragHandle={true}
          helperClass="sortableHelper"
          moduleHandler={(i) => moduleHandler(i)}
        />

        <div id="footer">
          <Button
            borderColor="success"
            icon="Check"
            text="Okidokily"
            txtColor="success"
            type="sub"
            onClick={() => props.close()}
          />
        </div>
      </div>
    </div>
  );
};

export default AddModule;
