import { Fragment, useState } from "react";
import Nav from "./Nav";

import axios from "axios";

const PdfPage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [vacatureData, setVacatureData] = useState({
    data: [],
  });
  let title = "";

  axios
    .get(
      `https://onepage-78a00-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/job_application/vacatures/${props.match.params.id}.json`
    )
    .then((resp) => {
      vacatureData.data = resp.data;
      setIsLoaded(true);
    });

  return (
    <Fragment>
      <Nav hover_animation="underline"/>
      {isLoaded && (
        <div className="pdf-page__container">
          <div className="pdf-page__data">
            <div className="pdf-page__img-container">
              <img src={vacatureData.data.image} />
            </div>
            <div className="pdf-page__content-container">
              <div className="pdf-page__content">
                <h1>{vacatureData.data.title}</h1>
                <p>{vacatureData.data.text}</p>
              </div>
              <div>
                <strong>
                  bij intresse twijfel zeker niet en neem contact met ons op via{" "}
                  <a
                    href="mailto:kimberly.vcz@ad-nederename.be"
                    data-node-uid="340"
                  >
                    kimberly.vcz@ad-nederename.be
                  </a>
                </strong>
              </div>
            </div>
          </div>
          <embed
            src={`https://ad-delhaize-nederename.be/vacatures/${vacatureData.data.title.toLowerCase()}.pdf`}
          ></embed>
        </div>
      )}
    </Fragment>
  );
};

export default PdfPage;
