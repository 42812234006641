import React from 'react'

const TarteAMoi = () => {


    return (
        <section id="tarteAMoi">
            <div className="tarte">
                <div className="logo"></div>
                <a href="https://www.tarteamoi.be/nl/onze-taarten#all-products" target="_blank">
                    <p>Bestel hier je lievelingstaart!
                        <br /><br />&amp;
                        {/* kom hem afhalen bij ons in de winkel */}
                        Maandag t.e.m. zaterdag : voor 14u besteld <br/>
                        = morgen geleverd in jouw Delhaize


                    </p>
                </a>
            </div>
        </section>
    )
}

export default TarteAMoi