import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, OsnInputText, OsnInputDate, OsnTextarea, OsnCheckbox, OsnDndUpload } from "@identitybuilding/idb-react-ui-elements";
import Icon from "@identitybuilding/idb-react-iconlib";


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}


const Vacature = (props) => {
  // const voornaam = useRef();
  const [loaded, setLoaded] = useState(true);
  const [voornaam, setVoornaam] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [straat, setStraat] = useState("");
  const [postcode, setPostcode] = useState("");
  const [gemeente, setGemeente] = useState("");
  const [geboortedag, setGeboortedag] = useState("");
  const [ervaring, setErvaring] = useState("");
  const [message, setMessage] = useState("");
  const [cv, setCV] = useState("");
  const [error, setError] = useState("");
  const [jobExperience, setJobExperience] = useState("");
  const [jobChoise, setJobChoise] = useState({
    halftijds: false,
    voltijds: false,
    vakantiejob: false,
    weekendjob: false,
    flexijob: false,
  })
  const [department, setDepartment] = useState({
    kassa: false,
    slagerij: false,
    bakkerij: false,
    drank: false,
    groenten: false,
  })


  const fileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);



  const { lang, translate, data, folder_name } = useSelector((state) => state.AdminReducer);
  const forceUpdate = useForceUpdate();

  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }

  useEffect(() => {
    var form = document.getElementById("vacancy-form");
    function handleForm(event) {
      event.preventDefault();
    }
    form.addEventListener('submit', handleForm);

    setTimeout(() => {
      
      let image = document.getElementById('vacature-img')
      let imageLoaded = image.complete && image.naturalHeight !== 0;
      if (!imageLoaded) {
        image.src = "https://o-sn.be/kml/test-onepages/ALGEMEEN/VACATURE_1.png"
      }
    }, 800);

  }, [])

  const jobChoiceHandler = (type) => {
    let copy = jobChoise
    copy[type] = !copy[type]
    setJobChoise(copy)
    forceUpdate()
  }

  const departmentHandler = (type) => {
    let copy = department
    copy[type] = !copy[type]
    setDepartment(copy)
    forceUpdate()
  }

  // const ManageFile = (e) => {
  //   let format = e[0].fileResult.split(';base64,')
  //   let ext = format[0].split('/')[1].replace('+xml', '')
  //   let available_extentions = ['pdf']
  //   if (available_extentions.includes(ext)) {
  //     setCV(e)
  //   }
  //   else {
  //     props.createNotification('warning', 'Alleen .pdf files worden aanvaard!')
  //   }
  // }

  const handleFileChange = (event) => {
    if (event.target.files[0].name.substring(event.target.files[0].name.length - 4) === '.pdf'){
    setFile(event.target.files[0]);
    // FILE TO BASE 64--------------------------------------------
    // Create a new FileReader
    const reader = new FileReader();

    // Set the file to be read
    reader.readAsDataURL(event.target.files[0]);

    // Add an event listener to be called when the file is loaded
    reader.onload = () => {
      // The file's text will be printed here
      setBase64(reader.result);
    };}
    else {
      props.createNotification('error', translate('only_in_pdf'))
      document.getElementById('cv_file').value = "";
    }
  };

  const handleSubmit = (event) => {

  };




  const mailForm = () => {


    // --------------------------------------------
    let maildata = {
      lang: lang,
      voornaam: voornaam,
      achternaam: achternaam,
      email: email,
      tel: tel,
      straat: straat,
      postcode: postcode,
      gemeente: gemeente,
      geboortedag: geboortedag,
      ervaring: ervaring,
      message: message,
      jobExperience: jobExperience,
      jobChoise: jobChoise,
      department: department,
      cv: base64,
      data: data.colors,
    }

    if (voornaam === "") { props.createNotification('warning', 'Gelieve een voornaam in te vullen'); setError('voornaam'); forceUpdate(); }
    else if (achternaam === "") { props.createNotification('warning', 'Gelieve een achternaam in te vullen'); setError('achternaam') }
    else if (email === "") { props.createNotification('warning', 'Gelieve een email in te vullen'); setError('email') }
    else if (tel === "") { props.createNotification('warning', 'Gelieve een Mobiel of Telefoon nummer in te vullen'); setError('tel') }
    else if (straat === "") { props.createNotification('warning', 'Gelieve een straat in te vullen'); setError('straat') }
    else if (postcode === "") { props.createNotification('warning', 'Gelieve een postcode in te vullen'); setError('postcode') }
    else if (gemeente === "") { props.createNotification('warning', 'Gelieve een gemeente in te vullen'); setError('gemeente') }
    else if (geboortedag === "") { props.createNotification('warning', 'Gelieve uw geboortedatum in te vullen'); setError('geboortedag') }
    else if (message === "") { props.createNotification('warning', 'Gelieve uw motivatie in te vullen'); setError('message') }
    else if (jobChoise.halftijds === false && jobChoise.voltijds === false && jobChoise.vakantiejob === false && jobChoise.weekendjob === false && jobChoise.flexijob === false) { props.createNotification('warning', 'Gelieve minstens één jobtype aan te duiden'); }
    else if (department.kassa === false && department.slagerij === false && department.bakkerij === false && department.drank === false && department.groenten === false) { props.createNotification('warning', 'Gelieve minstens één afdeling aan te duiden'); }
    else if (base64 === "") { props.createNotification('warning', 'Gelieve een cv uploaden'); }

    else {
      const headers = { "Content-Type": "application/json" };
      axios.post(`https://management.100procentlokaal.be/core/api/onepage/mail/${data.establishment_number}/?lang=${lang}`, {
      // axios.post(`http://localhost:8000/core/api/onepage/mail/${data.establishment_number}/?lang=${lang}`, {
        headers: headers,
        data: maildata,
      })
        .then(() => {

        })
        .catch(() => {
          setVoornaam('')
          setAchternaam('')
          setEmail('')
          setTel('')
          setStraat('')
          setPostcode('')
          setGemeente('')
          setGeboortedag('')
          setErvaring('')
          setMessage('')
          setJobExperience(false);
          setJobChoise({
            halftijds: false,
            voltijds: false,
            vakantiejob: false,
            weekendjob: false,
            flexijob: false,
          })
          setDepartment({
            kassa: false,
            slagerij: false,
            bakkerij: false,
            drank: false,
            groenten: false,
          })
          // setLoaded(false)
          // setLoaded(true)
          // forceUpdate()
          props.createNotification('info', lang === 'nl' ? 'Bedankt voor jouw sollicitatie, wij nemen zo snel mogelijk contact op!' : 'Merci pour votre candidature, nous vous contacterons dès que possible!')
          setTimeout(() => {
              window.location.href = window.location.href
          }, 5000);
        })
    }
  };

  return (
    loaded &&
    <section id="vacature">

      <div className="vacature-body">
        <div id="jobApplication">
          <div className="vacature-image__container">
            <img
              className="vacature-image"
              id="vacature-img"
              // src="https://www.delhaizeproxymuizen.be/extra/wp-content/uploads/2022/08/2-724x1024.jpg"
              src={`https://o-sn.be/kml/test-onepages/${folder_name}/vacature.png`}

            />
          </div>
          <div className="vacature-content">
            <div>
              {/* <h3>{translate('vacancy')}: {lang === "nl" ? "Traiteurmedewerker" : "Employé traiteur"}</h3> */}
              <h3>{translate('are_you_ready_for_a_new_adventure')}</h3>
              {/* <p>
          Wij zijn op zoek naar een traiteurmedewerker voor de AD Delhaize Nederename. Ben je gedreven en gemotiveerd? Zoek je een job dicht bij huis? Heb je een hart voor voeding? Werk je graag in een team waar we respect hebben voor elkaar en het beste in elkaar trachten naar boven te halen? Je komt terecht in een stabiel familiaal bedrijf met een rijke geschiedenis dat deel uitmaakt vande ijzersterke Ahold groep. Een familiaal bedrijf in volle expansie dat op zoek is naar mensenmet een hands-on mentaliteit. Duurzaam ondernemen, innoveren en bouwen aan een gezondelevensstijl staan bij ons voorop!
          </p> */}
            </div>
              <form
                id="vacancy-form"
                onSubmit={() => mailForm()}
                className="jobForm"
                action="/"
                encType="text/plain"
              >
                <div className="jobFormWrap three">
                  <OsnInputText
                    required
                    title={translate('firstname')}
                    onChange={(e) => {setVoornaam(e.target.value); setError('')}}
                    error={error === 'voornaam' ? true : false}
                    value={voornaam}
                  />
                  <OsnInputText
                    required
                    title={translate('lastname')}
                    onChange={(e) => {setAchternaam(e.target.value); setError('')}}
                    error={error === 'achternaam' ? true : false}
                    value={achternaam}
                  />
                  <OsnInputText
                    required
                    title={translate('email')}
                    onChange={(e) => {setEmail(e.target.value); setError('')}}
                    error={error === 'email' ? true : false}
                    value={email}
                  />
                </div>
                <div className="jobFormWrap three">
                  <OsnInputText
                    required
                    title={translate('street')}
                    onChange={(e) => {setStraat(e.target.value); setError('')}}
                    error={error === 'straat' ? true : false}
                    value={straat}
                  />
                  <OsnInputText
                    required
                    title={translate('postal_code')}
                    onChange={(e) => {setPostcode(e.target.value); setError('')}}
                    error={error === 'postcode' ? true : false}
                    value={postcode}
                  />
                  <OsnInputText
                    required
                    title={translate('municipality')}
                    onChange={(e) => (setGemeente(e.target.value))}
                    error={error === 'gemeente' ? true : false}
                    value={gemeente}
                  />
                </div>
                <div className="jobFormWrap two">
                  <label className="birthdate">
                    <div>
                      <p className="label">{translate('date_of_birth')}</p>
                      <OsnInputDate
                        onChangeDate={(e) => {setGeboortedag(e.target.value); setError('')}}
                        template={1}
                        error={error === 'geboortedag' ? true : false}
                        pattern={"\d{2}-\d{2}-\d{4}"}
                        value={geboortedag}
                      />
                    </div>
                  </label>
                  <OsnInputText
                    required
                    title={translate('phone')}
                    onChange={(e) => {setTel(e.target.value); setError('')}}
                    error={error === 'tel' ? true : false}
                    value={tel}
                  />
                </div>
                <div className="jobFormWrap full">
                  <h5>{translate('job_type')}</h5>
                  <div className="job_choice">
                    <OsnCheckbox
                      checked={jobChoise.halftijds}
                      name='Halftijds'
                      onChange={() => jobChoiceHandler('halftijds')}
                      value={translate('half_time')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={jobChoise.voltijds}
                      name='Voltijds'
                      onChange={() => jobChoiceHandler('voltijds')}
                      value={translate('full_time')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={jobChoise.weekendjob}
                      name='Weekendjob'
                      onChange={() => jobChoiceHandler('weekendjob')}
                      value={translate('weekendjob')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={jobChoise.vakantiejob}
                      name='Vakantiejob'
                      onChange={() => jobChoiceHandler('vakantiejob')}
                      value={translate('vacation_job')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={jobChoise.flexijob}
                      name='Flexi-job'
                      onChange={() => jobChoiceHandler('flexijob')}
                      value={translate('flexi_job')}
                      position='left'
                    />
                  </div>
                </div>
                <div className="jobFormWrap full">
                  <h5>{translate('you_have_experience_in_sales')}</h5>
                  <div className="field_experience">
                    <OsnCheckbox
                      checked={jobExperience}
                      name='yes_experience'
                      onChange={() => setJobExperience(true)}
                      value={translate('yes_message')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={jobExperience === false}
                      name='no_experience'
                      onChange={() => setJobExperience(false)}
                      value={translate('no_message')}
                      position='left'
                    />
                  </div>
                </div>
                <div className="jobFormWrap full">
                  <h5>{translate('department')}</h5>
                  <div className="department">
                    <OsnCheckbox
                      checked={department.kassa}
                      name='Kassa'
                      onChange={() => departmentHandler('kassa')}
                      value={translate('cash_desk')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={department.slagerij}
                      name='Slagerij'
                      onChange={() => departmentHandler('slagerij')}
                      value={translate('butchery')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={department.bakkerij}
                      name='Bakkerij'
                      onChange={() => departmentHandler('bakkerij')}
                      value={translate('bakery')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={department.drank}
                      name='Wijnen & sterke drank'
                      onChange={() => departmentHandler('drank')}
                      value={translate('Wines_spirits')}
                      position='left'
                    />
                    <OsnCheckbox
                      checked={department.groenten}
                      name='Groenten en fruit'
                      onChange={() => departmentHandler('groenten')}
                      value={translate('fruits_and_vegetables')}
                      position='left'
                    />
                  </div>
                </div>


                <div className="jobFormWrap three">
                  <label className={`motivatie ${error === 'motivation' ? 'error' : ''} ${message !== "" ? 'active' : ''}`}>
                    <div>
                      <textarea
                        htmlFor="message"
                        id="ja_message"
                        // placeholder={translate('motivation')}
                        onChange={(e) => setMessage(e.target.value)}
                        name="message"
                        value={message}
                        required
                      ></textarea>
                      <p className="motivatieLabel">{translate('motivation')}</p>
                    </div>
                  </label>
                </div>

                <div className="jobFormWrap send">
                  <h5 style={{"alignSelf": "flex-start"}}>{translate('upload_your_cv')} <span style={{ "fontSize": "12px", color: "var(--grey-70)" }}>({translate('only_in_pdf')})</span></h5>

                  {/* <OsnDndUpload
                    maxSize={500000000}
                    type="image"
                    getBase64={(e) => ManageFile(e)}
                    onDrop={(e) => ManageFile(e)}
                  /> */}
                  <input type="file" ref={fileInput} onChange={handleFileChange} className="cv_upload" id="cv_file" />
                  <button id="ja_send" type="submit">
                    <Icon name="Mail" />{translate('send')}
                  </button>
                </div>
              </form>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Vacature;
