import React from "react";

const Nav = (props) => {
    return (
        <div className="testBlock">
            <div className="testTitle">Navigation</div>
            <div>
                <span>Background:</span>
                <ul>
                    <li >white</li>
                    <li >transparent</li>
                </ul>
            </div>
            <div>
                <span>Hover animation:</span>
                <ul>
                    <li >color</li>
                    <li >underline</li>
                    <li >grow</li>
                    <li >float</li>
                    <li >background</li>
                </ul>
            </div>
            <div>
                <span>Navigation position:</span>
                <ul>
                    <li>left</li>
                    <li>center</li>
                    <li>right</li>
                </ul>
            </div>
        </div>

    );
}
export default Nav
