import axios from "axios";

const state = () => {
  return {
    token: null,
    user: null,
    admin: undefined,
  };
};

const getters = {
  isLoggedIn(state) {
    return state.admin === true;
  },
  isAdmin(state) {
    if (!state.user) {
      return false;
    }

    return state.user.role === "78b6335f-b448-46d6-8086-65057ba5fae0";
  },
};

const mutations = {
  SET_TOKEN(token) {
    state.token = token;
  },
  SET_USER(user) {
    state.user = user;
  },
};

const actions = {
  login(user, password) {
    axios
      .get(
        "https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/users.json"
      )
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].user === user &&
            response.data[i].password.toString() === password
          ) {
            sessionStorage.setItem("access_token", response.data[i].token);
            mutations.SET_TOKEN(response.data[i].token);

            if (
              response.data[i].token === "78b6335f-a782-46d6-6175-65057ba5fae0"
            ) {
              state.admin = true;
              sessionStorage.setItem("is_admin", state.admin);
            } else if (
              response.data[i].token === "78b6335f-b448-46d6-8086-65057ba5fae0"
            ) {
              state.admin = false;
              sessionStorage.setItem("is_admin", state.admin);
            }

            return;
          } else {
            sessionStorage.setItem(
              "access_token",
              "78b6335f-b448-46d6-8086-65057ba5fae0"
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getSocialData(instaId) {
    let user = "";
    let password = "";

    axios
      .get(
        `https://api.instagram.com/oauth/authorize
        ?client_id=506273896435183
        &redirect_uri=https://socialsizzle.herokuapp.com/auth/
        &scope=user_profile,user_media
        &response_type=code`
      )
      .then((res) => {
        sessionStorage.setItem("res", res);
      });
  },
  sessionLogin(context) {
    const token = sessionStorage.getItem("access_token");

    if (!token) {
      return;
    }

    return this.$axios("/users/me", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        context.commit("SET_TOKEN", token);
        context.commit("SET_USER", response.data.data);
      })
      .catch(() => {
        context.dispatch("logout");
      });
  },
  logout(context) {
    mutations.SET_TOKEN(null);
    mutations.SET_USER(null);
    state.admin = undefined;
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("is_admin");
  },
};

export default { state, getters, mutations, actions };
