import { Fragment } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import Footer from "./Footer";

const VacatureSuperplanner = (props) => {
    const { data, translate } = useSelector((state) => state.AdminReducer);
    let settings = {
        dots: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
    ]
    };
    return (
        <section id="winkelenBijOns">
            <video controls>
                <source src={require('../library/winkelenbijons.mp4').default} />
            </video>
            <h3>AD Delhaize Nederename: VERRAST, VERWENT, VERLEIDT EN VERMAAKT!</h3>
            <div className="content">
                <p>
                    Al 3 generaties lang staan klanten bij ons centraal. In 1994 startte de familie Vanden Hautte, als één van de eerste zelfstandige Delhaize uitbaters in België, hun eigen winkel. Intussen groeiden wij uit tot de grootste in Oudenaarde en omstreken.
                    <br/>
                    <br/>
                    Met een winkeloppervlakte van 2100m², 220 parkeerplaatsen, 10 kassa’s,  45 medewerkers en 60 jobstudenten creëren wij een unieke winkelbeleving. AD Delhaize Nederename is niet voor niets de nummer 8 Delhaize supermarkt in België.
                    <br/>
                    <br/>
                    Onze persoonlijke service samen met een verrassend groot assortiment, met meer dan 18.000 verschillende producten waarvan 50% in de versmarkt, gaan hand in hand. Elke dag gaan wij samen met onze medewerkers tot het uiterste om onze klanten te bedienen.
                    <br/>
                    <br/>
                    Onze eigen traiteurafdeling is een grote troef en heeft een bijzondere waardering bij onze klanten!  Met 1.800 dagschotels per week waarvan 55 verschillende variaties in eigen bereidingen zijn wij een van de grootste in België.
                    <br/>
                    <br/>
                    Aan onze bedieningstoog verwelkomen we u met de glimlach en geven we het nodige advies.
                </p>
                <p>
                    We versnijden op vraag een lekker stukje Belgische, Franse of Italiaanse kazen! Wenst u liever een kaas- of raclette schotel dan kan dit ook vooraf als bestelling doorgegeven worden.
                    <br/>
                    <br/>
                    Gedroogde of gekookte ham, gevogelte charcuterie, pasteien, Italiaanse of Spaanse specialiteiten… al dat lekkers en steeds vers!
                    Houdt u van aperitieven? Ontdek ook ons ruim assortiment tapas.
                    <br/>
                    <br/>
                    Hebt u omwille van een plots tijdgebrek nog geen lunch voorzien dan kan u bij ons altijd binnenspringen voor een lekkere maaltijdsalade. Lekker en gezond. Ons aanbod varieert dagelijks.
                    <br/>
                    <br/>
                    Proef & geniet ook van onze buffetten, ontbijtmanden, fondue, gourmet, barbecueschotels,… Blader even door onze jaarfolder (link) en laat je verleiden. Vraag zeker naar onze voorwaarden voor thuislevering!
                    <br/>
                    <br/>
                    Ben je op zoek naar wat lekkers uit onze eigen streek? Ook bij deze culinaire ontdekkingstocht helpt ons team jou graag verder.
                </p>
                <p>
                    Een geschenkmand boordevol lekkers, volledig gevuld naar uw wensen? Dat kan! Jaarlijks worden er meer dan 4000 stuks door ons team samengesteld en netjes ingepakt.
                    <br/>
                    <br/>
                    Naast kranten en tijdschriften kan je bij AD Nederename ook terecht om een gokje te wagen met de Lotto kansspelen.
                    Een pakketje versturen, een zending ophalen bij bpost of Bol.com? U hoeft niet verder te rijden….u kan het bij ons allemaal.
                    <br/>
                    <br/>
                    Hou je van koopjes? Zoek je steeds de beste aanbieding & prijs? Je bent fan van gratis artikelen? Dan ben je bij AD Delhaize Nederename aan het juiste adres.
                    Onze Delhaize folder en scheurfolder geeft heel het jaar door de sterkste 1+1 promoties en tal van gratis producten.
                    <br/>
                    <br/>
                    We willen jou als fan/volger van AD Nederename  uiteraard als eerste op de hoogte houden. Like daarom onze facebookpagina en blijf op de hoogte van nieuwe smaakmakers, promoties, acties, en zoveel meer.
                    <br/>
                    <br/>
                    <span className="highlight">Het voltallige Team van AD Delhaize Nederename wenst u ook in {new Date().getFullYear()} van harte welkom!</span>
                </p>
            </div>
            <Slider {...settings}>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
                <figure>
                    <img src={require('../library/images/about.png').default}/>
                </figure>
            </Slider>
            <Footer
                theme={data.options.footer.theme}
                contact={data.options.footer.contact}
                sitemap={data.options.footer.sitemap}
                name={data.establishment_name}
                enterprise_name={data.enterprise_name}
                address={data.address.line_1}
                city={data.address.line_2}
                phone={data.telephone}
                cellphone={data.cellphone.length > 0 ? data.cellphone[0] : ""}
                vat={data.vat_number}
                socialMedia={data.social_media}
              />
        </section>
    );
};

export default VacatureSuperplanner;
