import React from "react";

const NewsArticles = (props) => {
    return (
        <div className="testBlock">
            <div className="testTitle">News articles</div>
            <div>
                <span>Active:</span>
                <ul>
                    <li >yes</li>
                    <li >no</li>
                </ul>
            </div>
            <div>
                <span>Theme:</span>
                <ul>
                    <li >light</li>
                    <li >dark</li>
                    <li >main</li>
                    <li >sub</li>
                </ul>
            </div>
            <div>
                <span>Template:</span>
                <ul>
                    <li >1</li>
                    <li >2</li>
                    <li >3</li>
                </ul>
            </div>
        </div>
    );
}
export default NewsArticles
